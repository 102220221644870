import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  TwitterIcon,
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton
} from "react-share";
import "./ResponsiveAppBar.css";

const pages = [
  { 'path': '/home', 'name': 'Home' },
  { 'path': '/about', 'name': 'About' },
  { 'path': '/further-reading', 'name': 'Further reading' },
  { 'path': '/extra', 'name': 'Extra' },
]

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Main navigation"
              aria-controls="mobile-nav"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              id="mobile-nav-button"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="mobile-nav"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.path} sx={{ minHeight: 0 }}>
                  <NavLink key={page.path} to={page.path} onClick={handleCloseNavMenu}>
                    {page.name}
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box id="main-nav" sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <NavLink key={page.path} to={page.path}>
                {page.name}
              </NavLink>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Stack className="nav-share" direction="row" spacing={2} sx={{ pt: 1 }}>
              <div>
                <TwitterShareButton url={window.location}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              </div>
              <div>
                <FacebookShareButton url={window.location}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
              </div>
              <div>
                <RedditShareButton url={window.location}>
                  <RedditIcon size={32} round={true} />
                </RedditShareButton>
              </div>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;