import * as React from 'react';
import { Button, Chip, TextField, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getActiveModeKey, setActiveMode, getModeByKey } from '../helper/DataModeHelper';
import EditIcon from '@mui/icons-material/Edit';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    let current = getActiveModeKey();
    this.state = {
      current: current,
      open: false,
      value: '',
      error: '',
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({
      open: false,
      error: '',
    });
  }

  handleInput(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit() {
    const key = this.state.value;
    const id = getModeByKey(key);
    if (id === null) {
      this.setState({ error: 'Sorry, that is not correct.' });
      return;
    }
    setActiveMode(id, key);
    this.setState({ current: key });
    this.handleClose();
  }

  render() {
    return (
      <div>
        <Stack direction="row" spacing={1}>
          <Chip label={this.state.current} variant="outlined" />
          <Chip label="Change" icon={<EditIcon />} onClick={this.handleClickOpen} />
        </Stack>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>???</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the secret code.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="secret"
              label="Secret code"
              type="text"
              fullWidth
              variant="standard"
              onChange={this.handleInput}
              helperText={this.state.error}
              error={this.state.error !== ''}
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleSubmit}>Execute</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
