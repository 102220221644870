import React from 'react';
import Container from '@mui/material/Container';
import { getActiveModeId } from '../helper/DataModeHelper';
import ResponsiveAppBar from '../component/ResponsiveAppBar';
import Banner from '../component/Banner';
import HomeRE from './HomeRE';
import HomeDMC from './HomeDMC';

export default class Home extends React.Component {
  componentDidMount() {
    document.title = 'Home | Resident Evil Timeline App';
  }

  render() {
    const modeId = getActiveModeId();
    const isDMC = modeId && modeId.indexOf('DMC') === 0;
    return (
      <>
        <ResponsiveAppBar />
        <Banner />
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          {isDMC && <HomeDMC />}
          {!isDMC && <HomeRE />}
        </Container>
      </>
    );
  }
}
