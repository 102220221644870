import React, { useEffect } from 'react';
import { Container, Grid, Link, Typography } from '@mui/material';
import ResponsiveAppBar from '../component/ResponsiveAppBar';
import Banner from '../component/Banner';

function FurtherReading() {
  useEffect(() => {
    document.title = 'Further reading | Resident Evil Timeline App';
  });
  return (
    <>
      <ResponsiveAppBar />
      <Banner />
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h1">Further reading</Typography>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Okay but what should I play?</Typography>
            <p>We recommend following the main canon's "Major video games", and "Movies & TV series" in release order. If you do not have access to the original Resident Evil 1, 2, 3, & 4, it is fine to replace them with the remakes. If you do go down that route, consider playing Resident Evil 0 after the remake of the first Resident Evil. To go a step further, also check out the main canon's minor video games, mangas, and supplement material that you can easily access. Beyond that, just look up what interests you!</p>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Is there anything missing?</Typography>
            <p>Yes actually! There's so much more material that makes up the intellectual property. As far as commercial products go, there's also crossover promotions, art books, and other live-attractions. And beyond that, trailers, websites, game manuals, anniversaries, competitions, trade shows, and interviews are also part of the franchise. Even if they were listed, there are also pieces of lost media such as other browser games. There are also more sources of lore in material such as guidebooks scattered across notes, descriptions, Q&A comments etc. that can't be easily pointed to. The franchise is absolutely massive, and there's always something new on the way!</p>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Message from ParallelTraveler</Typography>
            <p>Hello! Thank you for checking out our project. I adore creating Resident Evil resources and this project’s concept and the process of making it were particularly special. It almost feels divine that this took collaboration and consultation with many communities and fans to build something I believe the whole fandom and beyond can appreciate and enjoy. So I hope that this will not only answer each person’s “timeline” question, but will also help others learn and become interested in even deeper aspects of the franchise. I have even more projects planned for the future, but in the meantime have fun here!</p>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">See also</Typography>
            <ul>
              <li><Link href="https://bioredqueeneffect.wordpress.com/" target="_blank" className="link-wrap">bioredqueeneffect.wordpress.com</Link> - ParallelTraveler's personal blog</li>
              <li><Link href="https://www.evilresource.com/" target="_blank" className="link-wrap">www.evilresource.com</Link> - Evil Resource's website</li>
              <li><Link href="https://residentevil.fandom.com/wiki/Timeline_by_media" target="_blank" className="link-wrap">residentevil.fandom.com/wiki/Timeline_by_media</Link> - static timeline on Resident Evil Wiki</li>
              <li><Link href="https://residentevil.fandom.com/wiki/Continuities" target="_blank" className="link-wrap">residentevil.fandom.com/wiki/Continuities</Link> - list of the different continuities on Resident Evil Wiki to help users understand the different Canons</li>
              <li><Link href="https://www.projectumbrella.net/chronological-timeline-of-materials.html" target="_blank" className="link-wrap">www.projectumbrella.net/chronological-timeline-of-materials.html</Link> - Chronological timeline of Materials on Project Umbrella</li>
              <li><Link href="https://residentevilpodcast.com/timeline" target="_blank" className="link-wrap">residentevilpodcast.com/timeline</Link> - Comprehensive chronology of lore by The Resident Evil Podcast</li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default FurtherReading;