import React, { useEffect } from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';
import ResponsiveAppBar from '../component/ResponsiveAppBar';
import Banner from '../component/Banner';
import DataSwitchDialog from '../component/DataSwitchDialog';
import Image from '../component/Image';

function Extra() {
  useEffect(() => {
    document.title = 'Extra | Resident Evil Timeline App';
  });
  return (
    <>
      <ResponsiveAppBar />
      <Banner />
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h1">Extra</Typography>

            <Typography sx={{ mt: 4, mb: 1 }} variant="h5alt" component="h2">Promotional art gallery</Typography>
            <p>To show support for the project, fans created clock themed art.</p>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} md={3}>
                <Image title="Credit: Cakestina" image="logo.png"></Image>
              </Grid>
              <Grid item xs={6} md={3}>
                <Image title="Credit: Alex Greyhound" image="poem-part-1.png"></Image>
              </Grid>
              <Grid item xs={6} md={3}>
                <Image title="Credit: Alex Greyhound" image="poem-part-2.png"></Image>
              </Grid>
              <Grid item xs={6} md={3}>
                <Image title="Credit: YellowgemEcto" image="cass-clock.png"></Image>
              </Grid>
              <Grid item xs={6} md={3}>
                <Image title="Credit: KennethPereira" image="leon-time.png"></Image>
              </Grid>
              <Grid item xs={6} md={3}>
                <Image title="Credit: ResiEvilCentral" image="first-aid-meal.png"></Image>
              </Grid>
            </Grid>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">OPERATION RE:QUEST</Typography>
            <p>Before revealing the App, we created an Alternate Reality Game (ARG) campaign on Twitter/X called “OPERATION RE:QUEST”. The game involved players finding and fulfilling “Requests” from Resident Evil characters asking for specific pictures of or from Resident Evil media and sending them back to the game account. The Requests ranged from locating calendars in Resident Evil 2, procuring a picture of a specific page from an art book, and even forming a team in Resident Evil Resistance with specific costumes. The puzzles and webpages are full of continuity and lore references, so we hope fans will look through the ARG and appreciate the ways it celebrates the franchise. The account is located here: <Link href="https://twitter.com/REBHQuest" target="_blank">twitter.com/REBHQuest</Link></p>
            <p>It was a ton of fun watching fans solve puzzles and fulfill Requests in real time, thank you those who submitted answers to the account to progress the game: bslenul, buliddez, trickyrpd, MoiraBurtz, Banana Wraith, Maro, Kangel, PbR8X3NEyaGSGus, ArmchairTitan, AlexGreyhound, & Resident_bio.</p>

            <Typography sx={{ mt: 4 }} variant="h5alt" component="h2">Mode</Typography>
            <DataSwitchDialog />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Extra;