import { hash} from '../helper/SimpleHash';

const SEED = 1024;

export function setActiveMode(id, key) {
  window.sessionStorage.setItem('db_id', id);
  window.sessionStorage.setItem('db_key', key);
  window.dispatchEvent(new Event('storage'));
}

export function getActiveModeId() {
  const id = window.sessionStorage.getItem('db_id') ?? '';
  const key = window.sessionStorage.getItem('db_key') ?? '';
  if (!isModeValid(id, key)) {
    return getDefaultMode().id;
  };
  return id;
}

export function getActiveModeKey() {
  const id = window.sessionStorage.getItem('db_id') ?? '';
  const key = window.sessionStorage.getItem('db_key') ?? '';
  if (!isModeValid(id, key)) {
    return getDefaultMode().key;
  };
  return key;
}

export function getModeByKey(key) {
  const hashed = hash(key, SEED);
  const modes = getModes();
  const ids = Object.keys(modes);
  for (let index in ids) {
    const id = ids[index];
    const mode = modes[id];
    if (!mode.protected && mode.key === key) {
      return id;
    }
    if (mode.protected && mode.key === hashed) {
      return id;
    }
  }
  return null;
}

export function isModeValid(id, key) {
  const modes = getModes();
  if (!modes[id]) {
    return false;
  }
  if (!modes[id].protected && modes[id].key === key) {
    return true;
  }
  const hashed = hash(key, SEED);
  if (modes[id].protected && modes[id].key === hashed) {
    return true;
  }
  return false;
}

function getDefaultMode() {
  return Object.values(getModes())[0];
}

function getModes() {
  return {
    'RE.1': { 'key': 'Survival Horror', 'protected': false },
    'DMC.1': { 'key': 496812569647062, 'protected': true },
    'DMC.2': { 'key': 8939579633887603, 'protected': true },
    'DMC.3': { 'key': 5893060293691837, 'protected': true },
    'DMC.4': { 'key': 7538432653076885, 'protected': true },
  };
}