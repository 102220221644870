import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ResponsiveAppBar from '../component/ResponsiveAppBar';
import Banner from '../component/Banner';

function NoPage() {
  useEffect(() => {
    document.title = 'Page not found | Resident Evil Timeline App';
  });
  return (
    <>
      <ResponsiveAppBar />
      <Banner />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h3" component="h1" align="center">404 Page Not Found</Typography>
            <Typography paddingTop="20px" variant="h6" component="p" align="center">It appears the page you have requested does not exist.</Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default NoPage;