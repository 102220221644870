import ReactDOM from "react-dom/client";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactGA4 from 'react-ga4';
import Home from "./page/Home";
import About from "./page/About";
import FurtherReading from "./page/FurtherReading";
import Extra from "./page/Extra";
import NoPage from "./page/NoPage";
import './index.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      main: '#ed1e1c',
    },
  },
  typography: {
    fontFamily: ['Roboto Serif', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h5alt: {
      color: '#bbb',
      fontWeight: 'normal',
    },
  },
});

export default function App() {
  ReactGA4.initialize('G-DZTHC49N0S');

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate to="home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/further-reading" element={<FurtherReading />} />
          <Route path="/extra" element={<Extra />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);