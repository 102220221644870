import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { getActiveModeId } from '../helper/DataModeHelper';
import { Container } from '@mui/material';
import './Banner.css';

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDMC: this.getIsDMC() };
    window.addEventListener('storage', () => {
      this.setState({ isDMC: this.getIsDMC() });
    })
  }

  getIsDMC() {
    const modeId = getActiveModeId();
    const isDMC = modeId && modeId.indexOf('DMC') === 0;
    return isDMC;
  }

  render() {
    return (
      <div className="banner-wrapper">
        <Container maxWidth="lg">
          <NavLink to="/home">
            {this.state.isDMC &&
              <img src="/images/title-dmc.png" alt="Devil May Cry Timeline App Logo" title="Devil May Cry Timeline App" className="banner" />
            }
            {!this.state.isDMC &&
              <img src="/images/title.png" alt="Resident Evil Timeline App Logo" title="Resident Evil Timeline App" className="banner" />
            }
          </NavLink>
        </Container>
      </div>
    );
  }
}